.drug-list {
  padding: 0 0 10px 0;
}

.dashboard {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

html::-webkit-scrollbar {
  display: none;
}

html,
body,
#root {
  height: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 0 auto;
  max-width: 1920px;
  height: 100%;
}
